@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .absolute-ctr {
      @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
    }
    .BTN {
      @apply relative px-4 py-3 flex flex-row w-80 text-lg font-semibold border justify-between items-center text-center transition-all duration-200 active:scale-[.98] disabled:scale-100 disabled:opacity-80;
    }
    .animated-btn {
      @apply relative transition-all duration-200 active:scale-[.98] cursor-pointer;
    }
    .normalBTN {
      @apply bg-secondary text-white border-transparent/*  active:bg-blue-500 */;
    }
    .tag-btn {
      @apply relative disabled:bg-slate-300 select-none inline-flex items-center justify-center px-8 py-[4%] shadow-sm text-lg text-black transition-all duration-200 rounded-2xl cursor-pointer bg-white active:scale-[.96] border-black border-2;
    }
    .tag-div {
      @apply overflow-hidden relative disabled:bg-slate-300 select-none inline-flex items-center justify-center px-[10%] py-[4%] shadow-sm text-lg text-black transition-all duration-200 rounded-2xl cursor-pointer bg-white border-primary border;
    }
    .INPUT {
      @apply outline-none overflow-hidden px-4 py-2 placeholder-[#44444480] placeholder-[14px] border-b border-[#44444480] resize-none flex justify-between items-center;
    }
  }
  
  