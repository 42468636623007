.Footer_Container {
    // border:1px solid red;
    height: 70vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #f7f7f8;
    .Section-1 {
        height: 30%;

        padding-left: 20px;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        gap: 20px;
        .Netre_Logo {
            height: 35px;
            width: 120px;
        }
        .Netra_Mail {
            display: flex;
            justify-content: center;
            align-items: start;
            .Mail_sym {
                margin-top: 2px;
                margin-left: 5px;
            }
        }
    }
    .Section-2 {
        height: 57%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .footer-item {
            width: 15%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: start;
            flex-direction: column;
            padding-left: 30px;
            gap: 20px;
            // border: 1px solid red;
            .item_title {
                // font-size: 1.2vw;
                color: #444444;
                text-transform: uppercase;
            }
            .Item_content {
                display: flex;
                justify-content: center;
                align-items: start;
                flex-direction: column;
                text-transform: capitalize;
                // opacity: 67%;
                color: #444444;
                gap: 20px;
                cursor: pointer;
                font-size: 0.9vw;
                .Social_btn_container {
                    border: 1px solid #444444;
                    border-radius: 5%;
                    opacity: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 2px 5px;
                    .Social_tags {
                        .tagline {
                            font-size: 0.5vw;
                            color: #444444;
                            font-weight: 600;
                            opacity: 100% !important;
                        }
                        .social_platform {
                            font-size: 0.8vw;
                            opacity: 100%;
                            font-weight: 800;
                        }
                    }
                }
                .signup_para {
                    width: 80%;
                }
            }
            &:last-child {
                width: 40%;
            }
        }
    }
    .Hr {
        width: 95%;
        height: 10px;
        border-top: 1px solid #444444;
        margin-left: 2.5%;
        opacity: 37%;
    }
    .CopyRight {
        display: flex;
        height: 10%;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 0.9vw;
    }
}

@media (max-width: 600px) {
    .plushvie-copyright-text {
      font-size: 14px !important;
    }
    .plushvie-copyright-logo {
      height: 26px !important;
    }
  }