@use "./variables";
.grecaptcha-badge { 
    visibility: hidden !important;
}
.App {
    // padding-top: 155px;
    width: 100vw;
    max-width: 100%;
    // @media screen and (max-width: variables.$small) {
    //     padding-top: 80px;
    // }
    // @media screen and (max-width: variables.$medium) {
    //     padding-top: 80px;
    // }
}
.no_product_found {
    width: 100%;
    height: 50dvh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    font-size: 20px;
    color: #999999;
}
.Home-banner {
    height: calc(100vh - 150px);
    //height: 100vh;
    object-fit: cover;
}
.netre-logo {
    height: 35px;
}
.header {
    z-index: 10;
}
.header-top-child {
    margin: 0 25px;
    padding: 25px 0;
}

.header-right-icons .vertical-rule {
    width: 1px;
    border-right: 1px solid var(--text-color);
    height: 20px;
}
.header-right-icons .material-symbols-outlined {
    cursor: pointer;
    padding: 0 18px;
    height: 32px;
}
.header-right-icons .material-symbols-outlined:last-child {
    padding-right: 0;
}
.header-bottom {
    gap: 3%;
    padding: 0px 25px;
    color: var(--text-color);
    border-top: 1px solid var(--color1);
    .items {
        cursor: pointer;
        font-size: 18px;
        padding: 20px 0;
    }
}

.BrowseGlasses {
    .heading {
        text-align: center;
        padding: 20px 0 0 0;
        font-size: 32px;
    }
}
.content {
    position: absolute;
    top: 20%;
    left: 5%;
    z-index: 1;
    .text-1 {
        color: var(--color2);
        text-transform: uppercase;
        font-size: 22px;
    }
    .text-2 {
        color: var(--text-color);
        text-transform: capitalize;
        font-size: 34px;
        margin: 25px 0;
    }
    .hr {
        border-bottom: 1px solid var(--color3);
        width: 50%;
        opacity: 0.5;
        margin: 25px 0;
    }
    .text-3 {
        color: var(--text-color);
        text-transform: capitalize;
        font-size: 16px;
        width: 70%;
        line-height: 1.8rem;
        margin: 25px 0;
    }
    .try-now-btn {
        font-size: 16px;
        padding: 12px 55px;
        text-transform: uppercase;
        color: var(--text-color);
        border: 1px solid var(--text-color);
        display: inline-block;
        position: relative;
        z-index: 3;
        cursor: pointer;
        .overlay {
            height: 50%;
            width: 50%;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            transition: all 0.5s;
        }
    }
    .try-now-btn:hover {
        color: var(--primary-bg-color);
        .overlay {
            height: 100%;
            width: 100%;
            background: var(--text-color);
            border: 1px solid var(--text-color);
        }
    }
}
.bluerim {
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    background: transparent linear-gradient(138deg, #ebf2fb 0%, #f7f8fa 100%) 0% 0% no-repeat padding-box;
    .box {
        height: 50vh;
        width: 70vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        display: flex;
        .left {
            height: 100%;
            width: 50%;
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                object-position: top right;
            }
        }
        .right {
            height: 100%;
            width: 50%;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 6%;
            img {
                width: 50%;
            }
        }
    }
}
.Toastify__toast-body > div:last-child {
    color: black;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
    // background-color: #444444;
}
.filled {
    path {
        fill: var(--color2);
    }
}
.Toastify__toast-body > div:last-child {
    color: black;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
    // background-color: #AC772B;
}

select {
    -webkit-appearance: none;
    appearance: none;
}

#readingPower {
    -webkit-appearance: none; /* For Safari */
    -moz-appearance: none; /* For Firefox */
    background: url("/assets/svg/down_arror_ic.svg") no-repeat right 10px center;
    background-size: 18px;
}
.login_shadow {
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);

    .arrow {
        width: 0px;
        height: 0px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 16px solid white;
    }
}
.modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    display: flex;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    padding: 1.25rem;
}

.modal-content {
    width: 95%;
    border: 0;
    overflow: hidden;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    outline: none;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #eeeeee;
}

.modal-header h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1f2937;
}
.modal-body {
    height: 80dvh;
}
.close-button {
    background: transparent;
    border: none;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    width: 2rem;
    height: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #9ca3af;
}
.agree_btn {
    width: 30%;
    height: 30px;
    background-color: #1f2937;
    color: white;
    text-align: center;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.background-overlay {
    opacity: 0.25;
    position: fixed;
    inset: 0;
    z-index: 999;
    background: black;
}

._qWTcH {
    gap: 5px !important;
    @media (min-width: 200px) and (max-width: 767px) {
        gap: 0px !important;
    }
}


#loader-wrapper {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: white;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  
  #loader-wrapper > img {
    /* width: 10vw; */
    position: absolute;
    top: -5px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 85px;
    height: 85px;
  }
  
  .lds-ellipsis div {
    position: absolute;
    top: 90px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #AC772B;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
  
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
  
    100% {
      transform: scale(0);
    }
  }
  
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
  
    100% {
      transform: translate(24px, 0);
    }
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }